"use client";
import { useBettor } from "@/utils/providers/bettor";
import React from "react";
import Button from "../primitives/button";
import Icon from "../primitives/icon";
import * as RadixDialog from "@radix-ui/react-dialog";
import mlClient from "@/ml-client";
import { useAuth } from "@clerk/clerk-react";
import Info from "../primitives/info";
import { useSearchParams } from "next/navigation";

export enum BecomeASellerType {
  Sidebar,
  PickSlip,
  Landing,
  Button,
  Account,
}

export default function BecomeASeller({
  type,
  onDismiss,
}: {
  type: BecomeASellerType;
  onDismiss?: () => void;
}) {
  return null;
  const { bettor } = useBettor();
  const { getToken } = useAuth();
  const searchParams = useSearchParams();
  const status = searchParams.get("onboarding");
  const [onboardingLink, setOnboardingLink] = React.useState<string | null>(
    null
  );
  const [error, setError] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const createOnboardingLink = async () => {
    setError(null);
    setOpen(true);
    setLoading(true);
    try {
      const token = await getToken();
      const response = await mlClient.post(
        "/marketplace/onboard-seller",
        {},
        token
      );
      if (!response.data.ok) {
        setError("Failed to create your link.");
      }
      setOnboardingLink(response.data.data);
    } catch (err) {
      console.error("Error in createOnboardingLink", err);
      setError("Something unexpected happened.");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (type !== BecomeASellerType.Landing) return;
    setOnboardingLink(null);
    if (status) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [status]);

  if (!bettor) return null;

  const render = (): React.ReactNode => {
    switch (type) {
      case BecomeASellerType.PickSlip:
        if (bettor.details.stripe_seller_onboarding_complete) return null;

        return (
          <>
            <div className="bg-secondary-200 p-4 relative rounded-lg">
              <div className="absolute inset-0 w-full h-full flex flex-row">
                <div className="flex flex-basis-6/12 w-full h-full bg-[url('/images/ui/depth-arrows-right.webp')] bg-[length:400px] bg-right-top"></div>
                <div className="flex flex-basis-6/12 w-full h-full bg-[url('/images/ui/depth-arrows-left.webp')] bg-[length:400px] bg-left-top"></div>
              </div>
              <div className="absolute top-4 right-4">
                <Button variant="circle" size="xs" color="tertiary">
                  <Icon name="close" size="xs" />
                </Button>
              </div>
              <div className="flex flex-col items-center gap-y-4">
                <div className="flex flex-col items-center gap-y-2 text-center relative">
                  <div className="text-title-lg-bold italic text-white uppercase">
                    Sell your Picks
                  </div>

                  <div className="text-title-sm text-secondary">
                    Sell your picks and earn some cold hard cash!
                  </div>
                </div>
                <Button size="sm" color="primary" href="/account/selling-picks">
                  Learn More
                </Button>
              </div>
            </div>
          </>
        );
      case BecomeASellerType.Sidebar:
        if (bettor.details.stripe_seller_onboarding_complete) return null;

        return (
          <>
            <div className="bg-secondary-300 px-4 py-8 relative rounded-lg">
              <div className="absolute inset-0 w-full h-full bg-no-repeat bg-[length:700px] bg-left-top bg-[url('/images/ui/depth-arrows-left.webp')]"></div>
              <div className="flex flex-col items-center gap-y-4">
                <div className="flex flex-col items-center gap-y-4 text-center">
                  <Icon
                    name="money-bags"
                    size="2xl"
                    className="text-primary fill-primary"
                  />
                  <div className="text-display text-white uppercase">
                    Sell your Picks
                  </div>
                  <div className="text-title-lg text-secondary max-w-xs">
                    Become a certified seller on Moneyline to sell your picks
                    and earn some cold hard cash!
                  </div>
                </div>
                <Button size="sm" color="primary" href="/account/selling-picks">
                  Learn More
                </Button>
              </div>
            </div>
          </>
        );

      case BecomeASellerType.Landing:
        return <>{renderDialog()}</>;
      case BecomeASellerType.Button:
        const resume: boolean =
          bettor.details.stripe_seller_id !== null &&
          !bettor.details.stripe_seller_onboarding_complete;
        return (
          <>
            {renderDialog()}
            <Button
              size="md"
              color="primary"
              onClick={() => createOnboardingLink()}
            >
              {resume ? "Resume Onboarding" : "Become a Seller"}
            </Button>
          </>
        );
      case BecomeASellerType.Account:
        if (bettor.details.stripe_seller_onboarding_complete) return null;

        return (
          <>
            {renderDialog()}
            <div className="bg-secondary-300 p-8 relative rounded-lg">
              <div className="absolute inset-0 w-full h-full bg-no-repeat bg-[length:700px] bg-left-bottom bg-[url('/images/ui/depth-arrows-right.webp')] z-0"></div>
              <div className="absolute inset-0 w-full h-full bg-no-repeat bg-[length:700px] bg-right-top bg-[url('/images/ui/depth-arrows-left.webp')] z-0"></div>
              <div className="flex flex-col gap-4">
                <div className="text-display-xl text-white uppercase">
                  Sell your picks on moneyline
                </div>
                <div className="text-secondary text-title-lg-medium max-w-2xl">
                  Become a certified seller on Moneyline to start selling your
                  picks and tracking your earnings. Fill out the registration
                  form and once approved, your account will be granted
                  permsissions to list picks for sale.
                </div>
                <div className="text-secondary text-title flex flex-row gap-2 items-center relative">
                  Registration completed in partnership with{" "}
                  <svg
                    viewBox="0 0 36 16"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 text-white"
                  >
                    <g clip-path="url(#clip0_13740_510413)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M35.9999 8.25747C35.9999 5.60155 34.76 3.50586 32.3901 3.50586C30.0102 3.50586 28.5703 5.60155 28.5703 8.23672C28.5703 11.3595 30.2702 12.9365 32.7101 12.9365C33.9 12.9365 34.8 12.6563 35.4799 12.2621V10.1872C34.8 10.5399 34.02 10.7578 33.0301 10.7578C32.0601 10.7578 31.2002 10.405 31.0902 9.18082H35.9799C35.9799 9.04595 35.9999 8.50647 35.9999 8.25747ZM31.0602 7.27188C31.0602 6.09954 31.7501 5.61192 32.3801 5.61192C32.9901 5.61192 33.64 6.09954 33.64 7.27188H31.0602Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.7116 3.50586C23.7317 3.50586 23.1017 3.9831 22.7517 4.31509L22.6218 3.67185H20.4219V15.7688L22.9217 15.2189L22.9317 12.2829C23.2917 12.5526 23.8217 12.9365 24.7016 12.9365C26.4915 12.9365 28.1214 11.4425 28.1214 8.15373C28.1114 5.14506 26.4615 3.50586 24.7116 3.50586ZM24.1117 10.654C23.5217 10.654 23.1717 10.4362 22.9317 10.1664L22.9217 6.3174C23.1817 6.01654 23.5417 5.80904 24.1117 5.80904C25.0216 5.80904 25.6516 6.86726 25.6516 8.22635C25.6516 9.61656 25.0316 10.654 24.1117 10.654Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.9766 2.89384L19.4864 2.3336V0.227539L16.9766 0.777398V2.89384Z"
                        fill="currentColor"
                      />
                      <path
                        d="M19.4864 3.68262H16.9766V12.7605H19.4864V3.68262Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.2886 4.45016L14.1286 3.68243H11.9688V12.7603H14.4686V6.6081C15.0586 5.80925 16.0585 5.95449 16.3685 6.06862V3.68243C16.0485 3.55794 14.8786 3.32969 14.2886 4.45016Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.2858 1.43066L6.84594 1.97015L6.83594 10.2803C6.83594 11.8157 7.94588 12.9466 9.42579 12.9466C10.2457 12.9466 10.8457 12.791 11.1757 12.6042V10.4982C10.8557 10.633 9.2758 11.1103 9.2758 9.57481V5.89179H11.1757V3.68198H9.2758L9.2858 1.43066Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.52986 6.3174C2.52986 5.91279 2.84984 5.75717 3.37981 5.75717C4.13977 5.75717 5.09972 5.99579 5.85967 6.42115V3.9831C5.02972 3.64073 4.20977 3.50586 3.37981 3.50586C1.34993 3.50586 0 4.60558 0 6.4419C0 9.30532 3.79979 8.84883 3.79979 10.0834C3.79979 10.5607 3.39981 10.7163 2.83984 10.7163C2.00989 10.7163 0.949947 10.3635 0.109994 9.8863V12.3555C1.03994 12.7705 1.97989 12.9468 2.83984 12.9468C4.91973 12.9468 6.34965 11.8782 6.34965 10.0212C6.33965 6.92951 2.52986 7.47937 2.52986 6.3174Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13740_510413">
                        <rect
                          width="36"
                          height="15.5455"
                          fill="white"
                          transform="translate(0 0.227539)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="text-display text-white uppercase text-center mt-12">How it works</div>
              <div className="flex flex-col xl:flex-row justify-between items-center mt-4 relative z-1">
                <div className="relative bg-secondary-300 flex flex-col p-8 gap-4 items-center justify-between w-[275px] h-[300px] border-2 border-solid border-[#3A3C3F] rounded-lg">
                  <div className="flex flex-col gap-4 items-center">
                    <Icon
                      name="newspaper-fold"
                      size="2xl"
                      className="fill-primary"
                    />
                    <div className="text-headline-lg uppercase text-white">
                      Register
                    </div>
                    <div className="text-body text-secondary text-center leading-snug">
                      Register to become a seller <br></br>on Moneyline.
                    </div>
                  </div>
                  <div className="text-label-lg italic text-secondary-400 text-center">
                    Moneyline partners with Stripe for secure financial
                    transactions & registration.
                  </div>
                </div>
                <div className="m-8 xl:m-4 rotate-90 xl:rotate-0">
                  <svg
                    width="45"
                    height="38"
                    viewBox="0 0 45 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.7576 19.0002L0.878909 5.12155L5.12155 0.878906L23.2429 19.0002L5.12155 37.1215L0.878906 32.8789L14.7576 19.0002ZM35.7576 19.0002L21.8789 5.12155L26.1215 0.878906L44.2429 19.0002L26.1215 37.1215L21.8789 32.8789L35.7576 19.0002Z"
                      fill="#B1ED40"
                    />
                  </svg>
                </div>

                <div className="relative bg-secondary-300 flex flex-col p-8 gap-4 items-center justify-between w-[275px] h-[300px] border-2 border-solid border-[#3A3C3F] rounded-lg">
                  <div className="flex flex-col gap-4 items-center">
                    <Icon
                      name="ticket-buy"
                      size="2xl"
                      className="fill-primary"
                    />
                    <div className="text-headline-lg uppercase text-white">
                      Create Picks
                    </div>
                    <div className="text-body text-secondary text-center leading-snug">
                      Add picks to your Pick Slip, click the $ sign, and set a
                      Sale price
                    </div>
                  </div>
                </div>
                <div className="m-8 xl:m-4 rotate-90 xl:rotate-0">
                  <svg
                    width="45"
                    height="38"
                    viewBox="0 0 45 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.7576 19.0002L0.878909 5.12155L5.12155 0.878906L23.2429 19.0002L5.12155 37.1215L0.878906 32.8789L14.7576 19.0002ZM35.7576 19.0002L21.8789 5.12155L26.1215 0.878906L44.2429 19.0002L26.1215 37.1215L21.8789 32.8789L35.7576 19.0002Z"
                      fill="#B1ED40"
                    />
                  </svg>
                </div>
                <div className="relative bg-secondary-300 flex flex-col p-8 gap-4 items-center justify-between w-[275px] h-[300px] border-2 border-solid border-[#3A3C3F] rounded-lg">
                  <div className="flex flex-col gap-4 items-center">
                    <Icon
                      name="money-bags"
                      size="2xl"
                      className="fill-primary"
                    />
                    <div className="text-headline-lg uppercase text-white">
                      Sell & Profit
                    </div>
                    <div className="text-body text-secondary text-center leading-snug">
                      Accrue money based on your sold picks.
                    </div>
                  </div>
                  <div className="text-label-lg italic text-secondary-400 text-center">
                    Money earned will be sent to your integrated Stripe account.
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-6 mt-8">
                <div className="flex flex-row gap-4 items-center justify-center">
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => createOnboardingLink()}
                  >
                    Become a Seller <Icon name="caret-right" size="xs" />
                  </Button>
                  <Button color="tertiary" size="sm">
                    <Icon name="question-book" className="text-primary-300" />
                    Seller Guide
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const renderContent = (): React.ReactNode => {
    if (loading && !onboardingLink) {
      return (
        <div className="p-8">
          <Info.Block
            variant="loading"
            title="Processing..."
            message="Loading your personalized seller experience"
            dark
          />
        </div>
      );
    }
    if (error) {
      return (
        <div className="p-8">
          <Info.Block
            variant="error"
            title="Sorry Sport!"
            message={error}
            dark
          />
        </div>
      );
    }
    if (onboardingLink) {
      return (
        <div className="flex flex-col gap-y-2 w-full h-full">
          <div
            className={`h-40 desktop-grid:h-80 w-full bg-center bg-cover desktop-grid:bg-contain bg-no-repeat`}
            style={{
              backgroundImage: `url('/images/announcements/onboard-seller.webp'`,
            }}
          ></div>
          <div className="pt-4 pb-8 px-4 flex flex-col gap-y-4 justify-center items-center w-full h-full">
            <div className="flex flex-col gap-y-4 w-full max-w-[28rem] items-center">
              <div className="text-display text-white uppercase text-center mb-2">
                Create a Seller Account
              </div>
              <div className="flex flex-col gap-y-1 items-center">

              <div className="text-title-lg-bold uppercase text-white mb-2">
                What you'll need:
              </div>
              <div className="text-base text-secondary">
                1. Your physical location (Turn off VPNs)
              </div>
              <div className="text-base text-secondary">
                2. A payment method (Debit/Bank Account)
              </div>
              </div>
            </div>
            <Button size="sm" href={onboardingLink} target="_blank">
              Continue to Stripe
            </Button>
          </div>
        </div>
      );
    }
    if (status === "refresh") {
      return (
        <div className="flex flex-col items-center justify-center gap-y-6 p-8">
          <Info.Block
            variant="error"
            title="Sorry Sport!"
            message="Your session expired."
            dark
          />
          <div className="flex flex-row gap-x-4 items-center justify-center">
            <Button onClick={() => createOnboardingLink()}>
              Resume Onboarding
            </Button>
            <RadixDialog.Close onClick={() => setOpen(false)}>
              <Button color="tertiary">Close</Button>
            </RadixDialog.Close>
          </div>
        </div>
      );
    }
    if (status === "return") {
      if (!bettor.details.stripe_seller_onboarding_complete) {
        return (
          <div className="flex flex-col items-center justify-center gap-y-6 p-8">
            <Info.Block
              variant="error"
              title="Whoa, there."
              message="You left the onboarding process."
              dark
            />
            <div className="flex flex-row gap-x-4 items-center justify-center">
              <Button onClick={() => createOnboardingLink()}>
                Resume Onboarding
              </Button>
              <RadixDialog.Close onClick={() => setOpen(false)}>
                <Button color="tertiary">Close</Button>
              </RadixDialog.Close>
            </div>
          </div>
        );
      }
      return (
        <div className="flex flex-col items-center justify-center gap-y-6 p-8">
          <Info.Block
            variant="success"
            confetti
            title="Success!"
            message="You're now a seller on Moneyline. Now go create picks and start selling!"
            dark
          />
          <RadixDialog.Close onClick={() => setOpen(false)}>
            <Button>Done</Button>
          </RadixDialog.Close>
        </div>
      );
    }
    if (status === "success") {
      return (
        <div className="flex flex-col items-center justify-center gap-y-6 p-8">
          <Info.Block
            variant="success"
            confetti
            title="Success!"
            message="You're now a seller on Moneyline. Now go create picks and start selling!"
            dark
          />
          <RadixDialog.Close onClick={() => setOpen(false)}>
            <Button>Close</Button>
          </RadixDialog.Close>
        </div>
      );
    }
  };

  const renderDialog = (): React.ReactNode => {
    return (
      <RadixDialog.Root open={open}>
        <RadixDialog.Portal>
          {/* ROOT */}
          <div className="fixed inset-0 z-[51]">
            <RadixDialog.Overlay className="bg-black bg-opacity-80 data-[state=open]:animate-overlayShow flex justify-center items-center -z-[1] h-full backdrop-blur-sm">
              {/* SCROLLABLE */}
              <div className="flex justify-center items-center w-full h-full">
                <RadixDialog.Content
                  className={`z-50 m-8 pt-8 pb-2 bg-secondary-100 overflow-x-hidden rounded-xl relative shadow-elevation-400 focus:outline-none max-h-[calc(100%_-_64px)] overflow-y-auto border-[2px] border-secondary-300`}
                  style={{
                    width: "600px",
                  }}
                >
                  <div className="absolute inset-0 w-full h-full -z-[1] bg-[url('/images/ui/depth-arrows-left.webp')] bg-[length:600px] bg-top bg-no-repeat"></div>
                  <div className="flex flex-col gap-y-4 items-center">
                    <RadixDialog.Close
                      onClick={() => setOpen(false)}
                      className="absolute top-4 right-4"
                    >
                      <Button variant="circle" size="xs" color="tertiary">
                        <Icon name="close" size="xs" />
                      </Button>
                    </RadixDialog.Close>
                    {renderContent()}
                  </div>
                </RadixDialog.Content>
              </div>
            </RadixDialog.Overlay>
          </div>
        </RadixDialog.Portal>
      </RadixDialog.Root>
    );
  };

  return <>{render()}</>;
}
