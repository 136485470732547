"use client";
import React, { HTMLAttributes } from "react";
import { MappedTeamDisplaySize, TeamDisplaySize } from "./display";
import { Sport, TeamBasic } from "common";
import Link from "next/link";
import { createTeamSlug, formatNameToUrl } from "@/utils/url";
import Image from "next/image";

interface TeamLogoProps extends HTMLAttributes<HTMLImageElement> {
  team: TeamBasic;
  size?: TeamDisplaySize;
  custom?: boolean;
  link?: boolean;
  sport?: Sport;
  splash?: boolean;
}
type TeamLogoVariance = {
  size: MappedTeamDisplaySize;
};
const teamLogoVariance: TeamLogoVariance = {
  size: {
    xs: "w-6 h-6",
    sm: "w-8 h-8",
    md: "w-[3.375rem] h-[3.375rem]",
    lg: "w-[3.375rem] h-[3.375rem] sm:w-[4.688rem] sm:h-[4.688rem]",
  },
};
export default function TeamLogo({
  team,
  size,
  custom,
  link,
  sport,
  splash,
  ...props
}: TeamLogoProps) {
  const [loading, setLoading] = React.useState(true);

  const classes: string = `${
    !custom ? teamLogoVariance.size[size || "md"] : undefined
  } flex ${!custom && !splash ? "relative" : ""} justify-center items-center ${
    props.className
  }`;

  if (link) {
    return (
      <Link href={createTeamSlug(sport || "nfl", team)} className={classes}>
        {loading && !custom && !splash && (
          <div className="absolute inset-0 bg-secondary-400 animate-pulse rounded-full w-full h-full"></div>
        )}
        <img
          src={team.logoUrl}
          className="m-auto max-h-[80%] w-full object-contain"
          alt={`${team.location} ${team.name} Logo`}
          onLoad={() => setLoading(false)}
        />
      </Link>
    );
  } else {
    return (
      <div className={classes}>
        {loading && !custom && !splash && (
          <div className="absolute inset-0 bg-secondary-400 animate-pulse rounded-full w-full h-full"></div>
        )}
        <img
          src={team.logoUrl}
          className="m-auto max-h-[80%] w-full object-contain"
          alt={`${team.location} ${team.name} Logo`}
          onLoad={() => setLoading(false)}
        />
      </div>
    );
  }
}
