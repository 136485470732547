"use client";
import { useWebSocketContext } from "@/utils/providers/websocket";
import React, { createContext, useContext, useEffect, useState } from "react";

interface RoomContextType {
  roomData: any;
}

const RoomContext = createContext<RoomContextType | undefined>(undefined);

export const useRoom = () => {
  const context = useContext(RoomContext);
  if (!context) {
    throw new Error("useRoom must be used within a GameRoomProvider");
  }
  return context;
};

interface RoomProviderProps {
  type: "game" | "bettor" | "profile";
  id?: number;
  children: React.ReactNode;
}

export const RoomProvider: React.FC<RoomProviderProps> = ({
  type,
  id,
  children,
}) => {
  const { data, socket, isConnected } = useWebSocketContext();
  const [roomData, setRoomData] = useState<any | null>(null);

  useEffect(() => {
    if (!id || !isConnected || !socket) return;
    const subscribeMessage = JSON.stringify({
      type: "subscribe",
      room: type,
      data: [String(id)],
    });
    socket.send(subscribeMessage);
    console.log(`Subscribed to ${type} room: ${id}`);

    return () => {
      if (socket) {
        const unsubscribeMessage = JSON.stringify({
          type: "unsubscribe",
          room: type,
          data: [String(id)],
        });
        socket.send(unsubscribeMessage);
        console.log(`Unsubscribed from ${type} room: ${id}`);
      }
    };
  }, [isConnected, socket, id]);

  useEffect(() => {
    if (!id || !data) return;
    if (data && (data.room === `${id}` || data.room === "*")) {
      console.log(`Received update for ${type} room:`, data);
      setRoomData(data);
    }
  }, [data, id]);

  return (
    <RoomContext.Provider value={{ roomData }}>{children}</RoomContext.Provider>
  );
};
